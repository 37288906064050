import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as galleryjDl5RbNP1cMeta } from "/vercel/path0/pages/gallery.vue?macro=true";
import { default as highlightsxqIDtGMklEMeta } from "/vercel/path0/pages/highlights.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91item_93VS61Z44hIgMeta } from "/vercel/path0/pages/packages/[item].vue?macro=true";
import { default as index1us1AaJFa5Meta } from "/vercel/path0/pages/packages/index.vue?macro=true";
import { default as privacykJ3SQnmrN4Meta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as reviewsp0OUKSelxuMeta } from "/vercel/path0/pages/reviews.vue?macro=true";
import { default as _91item_93bm9CCcgfkyMeta } from "/vercel/path0/pages/rooms/[item].vue?macro=true";
import { default as indexshyPbhYt8IMeta } from "/vercel/path0/pages/rooms/index.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
export default [
  {
    name: "page___en",
    path: "/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___it",
    path: "/it/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___fr",
    path: "/fr/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "contact___en",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___it",
    path: "/it/contatto",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "faq___en",
    path: "/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___it",
    path: "/it/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___fr",
    path: "/fr/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "gallery___en",
    path: "/gallery",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___it",
    path: "/it/galleria",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___fr",
    path: "/fr/galerie",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "highlights___en",
    path: "/location",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "highlights___it",
    path: "/it/ubicazione",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "highlights___fr",
    path: "/fr/attractions",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "packages-item___en",
    path: "/special-offers/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages-item___it",
    path: "/it/pachetti/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages-item___fr",
    path: "/fr/arrangements/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages___en",
    path: "/special-offers",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "packages___it",
    path: "/it/pachetti",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "packages___fr",
    path: "/fr/arrangements",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "privacy___en",
    path: "/privacy-cookies",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "privacy___it",
    path: "/it/privacidad-cookies",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "privacy___fr",
    path: "/fr/confidentialite-cookies",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "reviews___en",
    path: "/reviews",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "reviews___it",
    path: "/it/recensioni",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "reviews___fr",
    path: "/fr/revues",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "rooms-item___en",
    path: "/suites/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue")
  },
  {
    name: "rooms-item___it",
    path: "/it/camere/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue")
  },
  {
    name: "rooms-item___fr",
    path: "/fr/chambres/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue")
  },
  {
    name: "rooms___en",
    path: "/suites",
    component: () => import("/vercel/path0/pages/rooms/index.vue")
  },
  {
    name: "rooms___it",
    path: "/it/camere",
    component: () => import("/vercel/path0/pages/rooms/index.vue")
  },
  {
    name: "rooms___fr",
    path: "/fr/chambres",
    component: () => import("/vercel/path0/pages/rooms/index.vue")
  },
  {
    name: "sitemap___en",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___it",
    path: "/it/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___fr",
    path: "/fr/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  }
]